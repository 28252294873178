<template>
  <div id="layout-terms">
    <navbar class="fixed top-0 w-full" />
    <main class="pt-16 px-4">
      <div class="container mx-auto relative min-h-screen flex flex-col md:flex-row">
        <aside v-if="isSideMenuShown" class="terms-side-menu desktop-menu sticky top-96px w-200px h-25dvh">
          <ul class="mt-2">
            <li v-for="item in TABS" :key="item.name">
              <locale-link class="menu-item" :to="`/${item.name}`">{{ item.text }}</locale-link>
            </li>
          </ul>
        </aside>
        <div v-if="isFixedTabsBarShown" class="terms-tab-bar fixed top-16 left-0 w-full px-4 bg-white">
          <n-tabs :default-value="currentPath" size="large" justify-content="start" tab-style="padding: 12px 16px" :bar-width="170">
            <n-tab v-for="item in TABS" :key="item.name" :name="item.name">
              <locale-link class="menu-item" :to="`/${item.name}`">
                {{ item.text }}
              </locale-link>
            </n-tab>
          </n-tabs>
        </div>
        <div class="article w-full mt-12 md:mt-6 mb-20 px-4">
          <slot> </slot>
        </div>
      </div>
    </main>
    <addendum />
    <login-modal />
  </div>
</template>

<script setup lang="ts">
// TODO: i18n
import { NTabs, NTab } from 'naive-ui'

import Navbar from '@/components/common/navbar.vue'
import Addendum from '@/components/common/addendum.vue'
import LoginModal from '@/components/login/login-modal.vue'

import { useAppStore } from '@/store/app'

const { t } = useI18n()

const app = useAppStore()
const route = useRoute()

const TABS = computed(() => [
  {
    name: 'policy',
    text: t('footer_service_agreement'),
  },
  {
    name: 'privacy',
    text: t('footer_privacy_policy'),
  },
])

const isSideMenuShown = computed(() => app.isDesktopScreen)
const isFixedTabsBarShown = computed(() => app.isMobile || app.isTablet)

const currentPath = computed(() => route.path.replace('/', ''))
</script>

<style lang="scss" scoped>
.terms-side-menu {
  .menu-item {
    @apply block p-2;
    @apply text-base text-black-10 hover:text-yellow-50 transition-colors;
  }
}

.terms-tab-bar {
  &::before {
    @apply absolute bottom-0 left-0 w-full h-2px bg-gray-30;
    content: '';
  }
}
</style>
